import React, { useEffect, useState } from "react";
import TextInput from "../../../components/customInputs/TextInput";
import { Box, CircularProgress, FormControl, IconButton, Typography } from "@mui/material";
import MulitlineInput from "../../../components/customInputs/MultilineInput";
import ModalContainer from "../../../components/ModalContainer";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ImageInput from "../../../components/customInputs/ImageInput";
import ContainedButton from "../../../components/buttons/ContainedButton";
import DropDown from "../../../components/customInputs/DropDown";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../api/api";
import { createPromiseToast } from "../../../utils/createPromiseToast";

const EditSingleBlog = ({
  open,
  handleClose,
  setBlogStatus,
  editData,
  handleEditBlogsClose,
}) => {
  const [value, setValue] = useState("");
  const [valueErr, setValueErr] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [image,setImage] = useState(null);
  
  const [categories, setCategories] = useState([]);

  // if(editData) setValue(editData?.description);

  console.log(editData, "editData");
  useEffect(() => {
    setValue(editData?.description);
  }, [editData]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Email is required"),
    // dateOfUpload: Yup.date().required("Date is required"),
    category: Yup.string().required("Category is required"),
    authorName: Yup.string().required("Author Name is required"),
  });

  const editBlogDetails = async(data) =>{
    await api
    .editBlog(data)
    .then((res) => {
      console.log(res, "api response");
      if (res.status === 200) {
        handleEditBlogsClose();
        handleClose();
        setBlogStatus("edited");
        setLoading(false);
        return {
          success: true,
          message: "Blog Edited successfully",
        };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.log(err, "api response");
      return {
        success: false,
        message: err.message,
      };
    })
    .then((td) => {
      createPromiseToast(td);
    });
  }

  const formik = useFormik({
    initialValues: {
      title: editData?.title ?? "",
      dateOfUpload: editData?.dateOfUpload ?? "",
      category: editData?.category ?? "",
      authorName: editData?.authorName ?? "",
      description: null,
      imageUrl: editData?.imageUrl ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      // handleLogin(values);
      if (value === null) {
        setValueErr(true);
      } else {
        setLoading(true);
        let data = {
          ...values,
          description: value,
          blogId: editData?.id,
        };
        if(image){
          const formdata = new FormData();
          formdata.append('image',image,image.name);
          api.uploadBanner(formdata).then(res=>{
            console.log(res,'value');
            // let  d = new Date();
            // values = { ...values, description: value,imageUrl:res.data.data.imageUrl,dateOfUpload:d.toISOString()};
            data={...data,imageUrl:res.data.data.imageUrl}
            editBlogDetails(data);
          }).catch(err=>setLoading(false));
        }else{
          editBlogDetails(data);
        }
      }
      console.log(values);
    },
  });

  console.log(value);

  useEffect(() => {
    api.getAllCategories().then((res) => {
      if (res.status === 200) {
        setCategories(res.data.data);
      }
    });
  }, []);

  return (
    <ModalContainer open={open}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "1rem",
          width: "100%",
          maxWidth: "60rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "2rem",
            pb: "1rem",
            borderBottom: "1px solid #D2D2D2",
          }}
        >
          <Typography variant="modalTitle">
            {editData?.title ? "Edit Blog" : "Upload Blog"}
          </Typography>
          <IconButton onClick={handleClose}>
            <ClearRoundedIcon color="primary" sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            p: "2.5rem",
            display: "flex",
            gap: "1.5rem",
            flexDirection: "column",
          }}
        >
          <DropDown
            label="Category"
            menuItem={categories}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.category}
            name={"category"}
            placeholder="Selected category"
            error={formik.errors.category}
            touched={formik.touched.category}
          />
          <TextInput
            label={"Title"}
            placeholder={"Enter Title"}
            type="text"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.title}
            touched={formik.touched.title}
            name={"title"}
          />
          <Box sx={{ display: "flex", gap: "5rem" }}>
            {/* <TextInput
              label={"Date of Upload"}
              placeholder={"Enter Date of Upload"}
              type="date"
              value={formik.values.dateOfUpload}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.dateOfUpload}
              touched={formik.touched.dateOfUpload}
              name={"dateOfUpload"}
            /> */}
            <TextInput
              label={"Enter Author Name"}
              placeholder={"Author name"}
              value={formik.values.authorName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.authorName}
              touched={formik.touched.authorName}
              name={"authorName"}
              type="text"
            />
          </Box>
          {/* <MulitlineInput
            rows={5}
            label={"Blog Description"}
            placeholder={"Enter blog description"}
          /> */}
          <Box>
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              placeholder="Write blog description"
            />
            {valueErr && (
              <Typography
                sx={{
                  color: "red",
                  fontSize: { xs: "1.2rem" },
                  mt: { xs: "0.2rem" },
                }}
              >
                {"Description is required"}
              </Typography>
            )}
          </Box>
          <ImageInput
            label={"Upload Blog Image"}
            placeholder={"No File Selected"}
            footerText={"Format .jpg, .png, .jpeg, size : max 100kb"}
            value={image}
            onChange={(e)=>{
              if (e.target.files) {
                setImage(e.target.files[0]);
              }
            }}
            onBlur={formik.handleBlur}
            name= {'imageUrl'}
          />
          <ContainedButton
            label={loading?<CircularProgress/>:"Edit"}
            onClick={formik.handleSubmit}
            type={"submit"}
            disabled={loading}
          />
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default EditSingleBlog;
