import React from "react";
import ModalContainer from "./ModalContainer";
import { Box, Typography } from "@mui/material";
import successImg from "../assets/images/successImg.png";

const SuccessModal = ({ open, handleClose, label }) => {
  return (
    <ModalContainer open={open} onClose={handleClose}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "1rem",
          px: "7rem",
          py: "5rem",
          display: "grid",
          placeItems: "center",
          gap: "2rem",
        }}
      >
        <img src={successImg} style={{ width: "10rem" }} alt='success' />
        <Box>
          <Typography
            sx={{
              fontFamily: "Urbanist",
              fontSize: "2.5rem",
              textAlign: "center",
            }}
          >
            Great!
          </Typography>
          <Typography
            sx={{
              fontFamily: "Urbanist",
              fontSize: "2.5rem",
              textAlign: "center",
            }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default SuccessModal;
