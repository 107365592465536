import { createContext } from "react";
import { openAxiosInstance, securedAxiosInstance } from "./instances";

const authAPI = {
    login: (data) => {
      return openAxiosInstance.post(`/admin/login`,data);
    },
}

const blogsAPI = {
    getAllBlogs:()=>{
      return securedAxiosInstance.get(`/blogs/images/getAllBlogs?page=1&pageSize=100`)
    },
    publishBlogs:(data)=>{
      return securedAxiosInstance.post(`/blogs/images/createBlog`,data)
    },
    deleteBlog:(id)=>{
      return securedAxiosInstance.delete(`blogs/images/deleteBlog/${id}`)
    },
    editBlog:(data)=>{
      return securedAxiosInstance.put(`blogs/images/editBlog`,data)
    },
    uploadBanner:(data)=>{
      return securedAxiosInstance.post(`blogs/images/uploadBanner`,data)
    },
    
}

const categoryAPI = {
  getAllCategories:()=>{
    return securedAxiosInstance.get(`blogs/category/getAllBlogCategory?page=1&pageSize=100&searchText=`)
  },
  createCategories:(data)=>{
    return securedAxiosInstance.post(`blogs/category/createCategory`,data)
  },
  deleteCategories:(id)=>{
    return securedAxiosInstance.delete(`blogs/category/deleteBlogCategory/${id}`)
  },
  updateCategory:(data)=>{
    return securedAxiosInstance.put(`blogs/category/editBlogCategory`,data)
  },
}

const videoAPI = {
  getAllVideos:()=>{
    return securedAxiosInstance.get(`blogs/videos/getAllBlogs?page=1&pageSize=100&searchText=`)
  },
  createVideoBlog:(data)=>{
    return securedAxiosInstance.post(`blogs/videos/createBlog`,data)
  },
  deleteVideoBlog:(id)=>{
    return securedAxiosInstance.delete(`blogs/videos/deleteBlog/${id}`)
  },
  updateVideoBlog:(data)=>{
    return securedAxiosInstance.put(`blogs/videos/editBlog`,data)
  },
  uploadVideoBanner:(data)=>{
    return securedAxiosInstance.post(`blogs/videos/uploadBlogVideo`,data)
  },
}
export const api = {
    ...authAPI,
    ...blogsAPI,
    ...categoryAPI,
    ...videoAPI,
  };
  
  export const Context = createContext();