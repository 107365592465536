import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

const TextInput = ({ label, formik, name, ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="inputTitle" component={"p"}>
        {label}
      </Typography>
      <Box sx={{ position: "relative",mt:'.5rem'}}>
        <input
          name={name}
          style={inputStyles}
          value={props.value}
          {...{
            ...props,
            type:
              props.type === "password"
                ? !showPassword
                  ? "password"
                  : "text"
                : props.type,
          }}
          step="0"
        />
        {props.type === "password" && (
          <IconButton
            onClick={handleClickShowPassword}
            sx={{
              position: "absolute",
              right: "1rem",
              transform: "translateY(15%)",
            }}
          >
            {showPassword ? (
              <IoEyeOff color="#003162" />
            ) : (
              <IoEye color="#003162" />
            )}
          </IconButton>
        )}
      </Box>
      {/* <Typography
        sx={{ color: "red", fontSize: { xs: "1.2rem" }, mt: { xs: "0.2rem" } }}
      >
        {formik?.errors[name] && formik?.touched[name] && formik?.errors[name]}
      </Typography> */}
      {props.error && props.touched ? (
        <Typography
          // fontFamily={"Plus Jakarta Sans"}
          sx={{ color: "red", fontSize: { xs: "1.2rem" }, mt: { xs: "0.2rem" } }}
        >
          {props.error}
        </Typography>
      ) : null}
    </Box>
  );
};

export default TextInput;

export const inputStyles = {
  borderRadius: ".5rem",
  background: "#0031621F",
  width: "100%",
  fontSize: "1.4rem",
  border: "none",
  outline: "none",
  minHeight: "4rem",
  color: "#003162",
  padding: "1.5rem 2rem",
  fontWeight: "500",
};
