import { Box, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import ContainedButton from "../buttons/ContainedButton";

const VideoInput = ({ label, placeholder,footerText, value,onChange,name}) => {
  const imageRef = useRef();
  console.log(value);
  return (
    <Box>
      <Typography component={"p"} variant="inputTitle">
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#D1DFE5",
          alignItems: "center",
          borderRadius: ".5rem",
          height: "5rem",
          mt:'.5rem'
        }}
      >
        <input
          type="file"
          ref={imageRef}
          style={{ display: "none" }}
          onChange={onChange}
          name={name}
          accept="video/*"
        />
        <Typography
          sx={{ flexGrow: 1, color: "#75848C", fontSize: "1.4rem", ml: "2rem" }}
        >
          {value ?value.name: placeholder}
        </Typography>
        <ContainedButton
          label={"Choose File"}
          width={"20rem"}
          onClick={() => imageRef.current.click()}
        />
      </Box>
      <Typography sx={{ color: "#75848C", fontSize: "1.2rem" }}>
        {footerText}
      </Typography>
    </Box>
  );
};

export default VideoInput;
