import React, { useState } from "react";
import ModalContainer from "../../../components/ModalContainer";
import { Box, Typography, CircularProgress } from "@mui/material";
import ContainedButton from "../../../components/buttons/ContainedButton";
import bgImage from "../../../assets/images/loginBackground.png";
import { api } from "../../../api/api";
import { createPromiseToast } from "../../../utils/createPromiseToast";

const PreviewBlogs = ({
  open,
  handleClose,
  onSuccess,
  previewData,
  setBlogStatus,
}) => {
  const [loading, setLoading] = useState(false);

  const publishBlog = async () => {
    setLoading(true);
    const data = { ...previewData };
    await api
      .publishBlogs(data)
      .then((res) => {
        console.log(res, "api response");
        if (res.status === 200) {
          onSuccess();
          setBlogStatus("created");
          setLoading(false);
          return {
            success: true,
            message: "Blog Published successfully",
          };
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "api response");
        return {
          success: false,
          message: err?.response?.data?.message,
        };
      })
      .then((td) => {
        createPromiseToast(td);
      });
  };

  return (
    <ModalContainer open={open}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "1rem",
          width: "100%",
          maxWidth: "70vw",
          // marginTop: "15%",
          // marginBottom: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "2rem",
            pb: "1rem",
            borderBottom: "1px solid #D2D2D2",
          }}
        >
          <Typography variant="modalTitle">Blog Preview</Typography>
        </Box>
        <Box
          sx={{
            py: "2.5rem",
            px: "10vw",
            display: "flex",
            gap: "1.5rem",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              aspectRatio: "5/2",
              overflow: "hidden",
              borderRadius: ".5rem",
            }}
          >
            <img
              src={previewData?.imageUrl}
              style={{ width: "100%", height: "100%" }}
              alt="blog"
            />
          </Box>
          <Box sx={{ borderBottom: "1px solid #E8E7E7", pb: 2 }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "3.5rem",
                fontWeight: "700",
                color: "#2D3748",
              }}
            >
              {previewData?.title}
            </Typography>
            <Box sx={{ display: "flex", gap: "5rem" }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  color: "#2D3748",
                }}
              >
                Written by {previewData?.authorName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                  color: "#2D3748",
                }}
              >
                {previewData?.dateOfUpload.substring(0, 10)}
              </Typography>
            </Box>
          </Box>
          <div dangerouslySetInnerHTML={{ __html: previewData?.description }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            width: "50rem",
            py: "5rem",
            alignSelf: "center",
            marginX: "auto",
          }}
        >
          <ContainedButton
            label={"Go Back"}
            backgroundColor={" #00316259"}
            color={"#003162"}
            onClick={handleClose}
          />
          <ContainedButton
            label={loading ? <CircularProgress /> : "Publish"}
            onClick={publishBlog}
            disabled={loading}
          />
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default PreviewBlogs;
