import React, { useEffect, useState } from "react";
import Login from "./unAuth/Login";
import AuthOutlet from "./auth/AuthOutlet";
import { Routes, Route, Navigate } from "react-router-dom";
import { RiFileList3Fill } from "react-icons/ri";
import { RiFileList3Line } from "react-icons/ri";
import { PiVideoFill } from "react-icons/pi";
import { PiVideo } from "react-icons/pi";
import { BiCategory } from "react-icons/bi";
import { BiSolidCategory } from "react-icons/bi";
import Blogs from "./auth/blogs/Blogs";
import Videos from "../routes/auth/videos/Videos";
import { useSelector ,useDispatch} from "react-redux";
import BlogCategory from "./auth/category/BlogCategory";
import { login } from "../store/authSlice";

export const navRoutes = [
  {
    key: 1,
    routeName: "Blogs",
    path: "/blogs",
    element: <Blogs />,
    activeIcon: RiFileList3Fill,
    inActiveIcon: RiFileList3Line,
  },
  {
    key: 2,
    routeName: "Videos",
    path: "/videos",
    element: <Videos />,
    activeIcon: PiVideoFill,
    inActiveIcon: PiVideo,
  },
  {
    key: 3,
    routeName: "Blog category",
    path: "/blog/category",
    element: <BlogCategory />,
    activeIcon: BiSolidCategory,
    inActiveIcon: BiCategory,
  },
];

const PageRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  console.log(isAuthenticated, "authenticated");
  
  const dispatch = useDispatch();

  useEffect(() => {
    const isAuth = JSON.parse(localStorage.getItem("isAuthenticated"));
    if (isAuth) {
      let user = JSON.parse(localStorage.getItem("user"));
      if (!user) return;
      dispatch(login(user));
    }
  }, []);

  return isAuthenticated ? <AuthRoutes /> : <UnAuthRoutes />;
};

const UnAuthRoutes = () => {
  return <Login />;
};

const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthOutlet />}>
        <Route path={"/"} element={<Navigate to={navRoutes[0].path} />} />
        {navRoutes.map((item) => (
          <Route path={item.path} element={item?.element} />
        ))}
      </Route>
    </Routes>
  );
  // return <AuthOutlet />;
};

export default PageRoutes;
