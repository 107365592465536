import React from "react";
import bgImg from "../../assets/images/loginBackground.png";
import { Box, Typography } from "@mui/material";
import ContainedButton from "../buttons/ContainedButton";

const VideoCard = ({onClick,item}) => {
  return (
    <Box sx={{ gap: "1rem", display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          width: "100%",
          aspectRatio: 1.3,
          borderRadius: "1rem",
          overflow: "hidden",
        }}
      >
        {/* <img src={item?.thumbnail} alt="" style={{ width: "100%", height: "100%" }} /> */}
        <video width="400" controls poster={item?.thumbnail}>
              <source src={item?.videoUrl} type="video/mp4" />
            </video>
      </Box>
      <Typography>
        {/* Precision in Practice: Mastering Surgical Techniques for Optimal Patient
        Outcomes */}
        {item?.title}
      </Typography>
      <ContainedButton label={"View"} onClick={onClick} />
    </Box>
  );
};

export default VideoCard;
