import React, { useState } from "react";
import ModalContainer from "../../../components/ModalContainer";
import { Box, IconButton, Typography } from "@mui/material";
import ContainedButton from "../../../components/buttons/ContainedButton";
import bgImage from "../../../assets/images/loginBackground.png";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { api } from "../../../api/api";
import { createPromiseToast } from "../../../utils/createPromiseToast";
import EditSingleBlog from "./EditSingleBlog";

const EditBlogs = ({
  open,
  handleClose,
  onSuccess,
  blogData,
  setBlogStatus,
  setEditdata,
  setBlogEditorVisible,
}) => {
  const [blogEditorVisible, setblogEditorVisible] = useState(false);

  const handleDeleteBlog = async () => {
    const con = window.confirm("Are you sure you want to delete");
    setBlogStatus("deleting");
    if (con) {
      await api
        .deleteBlog(blogData.id)
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            setBlogStatus("deleted");
            return {
              success: true,
              message: "Blog deleted successfully",
            };
          }
        })
        .catch((err) => {
          return {
            success: false,
            message: err?.response?.data?.message,
          };
        })
        .then((td) => {
          createPromiseToast(td);
        });
    }
  };

  return (
    <>
      <ModalContainer open={open}>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "1rem",
            width: "100%",
            maxWidth: "70vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "2rem",
              pb: "1rem",
              borderBottom: "1px solid #D2D2D2",
            }}
          >
            <Typography variant="modalTitle">Blog Preview</Typography>
            <IconButton onClick={handleClose}>
              <ClearRoundedIcon color="primary" sx={{ fontSize: "2.5rem" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              py: "2.5rem",
              px: "10vw",
              display: "flex",
              gap: "1.5rem",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                aspectRatio: "5/2",
                overflow: "hidden",
                borderRadius: ".5rem",
              }}
            >
              <img
                src={blogData?.imageUrl}
                style={{ width: "100%", height: "100%" }}
                alt="blog"
              />
            </Box>
            <Box sx={{ borderBottom: "1px solid #E8E7E7", pb: 2 }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "3.5rem",
                  fontWeight: "700",
                  color: "#2D3748",
                }}
              >
                {blogData?.title}
              </Typography>
              <Box sx={{ display: "flex", gap: "5rem" }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "1.5rem",
                    fontWeight: "700",
                    color: "#2D3748",
                  }}
                >
                  Written by {blogData?.authorName}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    color: "#2D3748",
                  }}
                >
                  {blogData?.dateOfUpload.substring(0, 10)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
                py: "2rem",
              }}
            >
              {/* <Typography>{blogData?.description}</Typography> */}
              <div dangerouslySetInnerHTML={{ __html: blogData?.description }}/>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              width: "50rem",
              py: "5rem",
              alignSelf: "center",
              marginX: "auto",
            }}
          >
            <ContainedButton
              label={"Delete Blog"}
              backgroundColor={"#FF6A6A"}
              color={"#FFFFFF"}
              onClick={handleDeleteBlog}
            />
            <ContainedButton
              label={"Edit Blog"}
              onClick={() => {
                setblogEditorVisible(true);
              }}
            />
          </Box>
        </Box>
      </ModalContainer>
      <EditSingleBlog
        open={blogEditorVisible}
        handleClose={() => setblogEditorVisible(false)}
        editData={blogData}
        setBlogStatus={setBlogStatus}
        handleEditBlogsClose = {handleClose}
      />
    </>
  );
};

export default EditBlogs;
