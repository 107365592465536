import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#003162",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: "Inter",
    allVariants: {
      fontFamily: "Poppins",
      fontWeight: "500",
    },
    titleText: {
      color: "#343C6A",
      fontFamily: "Inter",
      fontSize: "2.9rem",
      fontStyle: "normal",
      fontWeight: "600",
    },
    modalTitle: {
      fontWeight: "600",
      fontSize: "2rem",
      color: "#003162",
    },
    inputTitle: {
      color: "#111111",
      fontSize: "1.5rem",
      fontWeight: "600",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
});
