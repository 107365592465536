import React from "react";
import bgImg from "../../assets/images/loginBackground.png";
import { Box, Typography } from "@mui/material";
import ContainedButton from "../buttons/ContainedButton";

const BlogCard = ({ onClick, item }) => {
  console.log(item);
  return (
    <Box sx={{ gap: "1rem", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: "100%",
          aspectRatio: 1.3,
          borderRadius: "1rem",
          overflow: "hidden",
        }}
      >
        <img
          src={item.imageUrl}
          style={{ width: "100%", height: "100%" }}
          alt="blog"
        />
      </Box>
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          fontFamily: "Montserrat",
          fontSize: "1.4rem",
          fontWeight: "700",
        }}
      >
        {item.title}
      </Typography>
      <ContainedButton label={"View"} onClick={onClick} />
    </Box>
  );
};

export default BlogCard;
