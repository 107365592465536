import {
  Box,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import React, { useRef, useState } from "react";

const ImageInput = ({
  label,
  menuItem,
  onChange,
  onBlur,
  value,
  name,
  placeholder,
  error,
  touched,
}) => {
  console.log(menuItem, "menuItem", value);
  return (
    <Box>
      <Typography component={"p"} variant="inputTitle" mb={".5rem"}>
        {label}
      </Typography>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          name={name}
          placeholder={placeholder}
          sx={{
            width: "100%",
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": {
              border: 0,
              borderRadius: ".5rem",
              backgroundColor: "#0031621F",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
              borderRadius: ".5rem",
              backgroundColor: "#0031621F",
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
                borderRadius: ".5rem",
                backgroundColor: "#0031621F",
              },
            height: "5rem",
            borderRadius: ".5rem",
            overflow: "hidden",
          }}
          label="Select Category"
          // displayEmpty

          // disableUnderline
          // inputProps={{ "aria-label": "Without label" }}
          onBlur={onBlur}
          onChange={onChange}
        >
          {menuItem.map((dt) => {
            return (
              <MenuItem value={dt.name} sx={{ textTransform: "capitalize" }}>
                {dt.name}
              </MenuItem>
            );
          })}
        </Select>
        {error && touched ? (
        <Typography
          // fontFamily={"Plus Jakarta Sans"}
          sx={{ color: "red", fontSize: { xs: "1.2rem" }, mt: { xs: "0.2rem" } }}
        >
          {error}
        </Typography>
      ) : null}
      </FormControl>
    </Box>
  );
};

export default ImageInput;
