import { Box, Typography,CircularProgress } from "@mui/material";
import React, { useState } from "react";
import bgImage from "../../assets/images/loginBackground.png";
import sfsLogo from "../../assets/images/sfsLogo.png";
import TextInput from "../../components/customInputs/TextInput";
import ContainedButton from "../../components/buttons/ContainedButton";
import { Link ,useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/authSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../api/api";
import { createPromiseToast } from "../../utils/createPromiseToast";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);

  const handleLogin = async (value) => {
    console.log(value);
    setLoading(true);
    await api.login(value).then((res) => {
      console.log(res, "api response");
      if(res.status === 200) {
        dispatch(login(res.data));
        navigate('/blogs',{replace: true});
        return {
          success: true,
          message: "Logged in successfully",
        };
      }
      setLoading(false);
    }).catch((err) => {
      console.log(err, "api response");
      setLoading(false);
      return {
        success: false,
        message: err?.response?.data?.message,
      };
    }).then((td)=>{
      createPromiseToast(td);
    });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().trim().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        background: `url(${bgImage}) `,
        height: "100vh",
        backgroundSize: "100% 100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          padding: "4rem",
          backgroundColor: "#FFFFFF",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "3rem",
          borderRadius: "1rem",
        }}
      >
        <Box sx={{ width: "8rem", height: "8rem" }}>
          <img
            src={sfsLogo}
            alt="sfs logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: "2.5rem",
            fontFamily: " Poppins, sans-serif",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Sign in Into SFS Admin Panel
        </Typography>
        <form style={{ width: "90%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <TextInput
              placeholder="Enter email or user name"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <Box sx={{ width: "100%" }}>
              <TextInput
                placeholder="Enter your password"
                type={"password"}
                name={"password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.password}
                touched={formik.touched.password}
              />
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "1.3rem",
                    textAlign: "right",
                    mt: "1rem",
                    textDecoration: "none",
                  }}
                >
                  Forgot password ?
                </Typography>
              </Link>
            </Box>
            <ContainedButton label={loading?<CircularProgress/>:"Login"} onClick={formik.handleSubmit} type={'submit'} disabled={loading} />
        
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
