import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { BiSolidEdit } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { api } from "../../api/api";
import { createPromiseToast } from "../../utils/createPromiseToast";
import EditCategory from "../../routes/auth/category/EditCategory";

const CategoryCard = ({ item, setCategoryStatus }) => {

  const [editCategoryOpen,setEditCategoryOpen] = useState(false);

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      api
        .deleteCategories(item?.id)
        .then((res) => {
          if (res.status === 200) {
            setCategoryStatus("deleted");
            return {
              success: true,
              message: "Category deleted successfully",
            };
          }
        })
        .catch((err) => {
          return {
            success: false,
            message: "Something went wrong",
          };
        })
        .then((td) => {
          createPromiseToast(td);
        });
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "1.5rem",
        fontWeight: 500,
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        borderRadius: "1rem",
        paddingX: "2rem",
      }}
    >
      <Typography
        sx={{ textTransform: "capitalize", fontSize: "1.7rem", flex: 1 }}
      >
        {item?.name}
      </Typography>
      <Button
        variant="contained"
        onClick={()=> setEditCategoryOpen(true)}
        sx={{
          textTransform: "none",
          fontWeight: "500",
          fontFamily: "Poppins",
          aspectRatio: 1,
          minWidth: "4rem",
          maxWidth: "4rem",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          ":hover": {
            backgroundColor: "#003162",
          },
        }}
      >
        <BiSolidEdit size={25} />
      </Button>
      <Button
        variant="contained"
        onClick={handleDelete}
        sx={{
          textTransform: "none",
          fontWeight: "500",
          fontFamily: "Poppins",
          backgroundColor: "#FF7878",
          aspectRatio: 1,
          minWidth: "4rem",
          maxWidth: "4rem",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          ":hover": {
            backgroundColor: "#FF7878",
          },
        }}
      >
        <FiTrash2 size={20} />
      </Button>
      <EditCategory
        open={editCategoryOpen}
        handleClose={() => {
          setEditCategoryOpen(false);
        }}
        onSuccess={() => {
          setEditCategoryOpen(false);
          // setsuccessVisible(true);
        }}
        setCategoryStatus={setCategoryStatus}
        data={item}
      />
    </Box>
  );
};

export default CategoryCard;
