import { Modal } from "@mui/material";
import React from "react";

const ModalContainer = ({ open, onClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "grid",
        placeItems: "center",
        overflowY: "scroll",
        p: "2rem",
      }}
    >
      {children}
    </Modal>
  );
};

export default ModalContainer;
