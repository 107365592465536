import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContainedButton from "../../../components/buttons/ContainedButton";
import BlogCard from "../../../components/cards/BlogCard";
import AddBlog from "./AddBlogs";
import PreviewBlogs from "./PreviewBlogs";
import SuccessModal from "../../../components/SuccessModal";
import EditBlogs from "./EditBlogs";
import { api } from "../../../api/api";
import EditSingleBlog from "./EditSingleBlog";

const Blogs = () => {
  const [addBlogVisible, setAddBlogVisible] = useState(false);
  const [blogPreviewVisible, setBlogPreviewVisible] = useState(false);
  const [blogEditorVisible, setBlogEditorVisible] = useState(false);
  const [successVisible, setsuccessVisible] = useState(false);
  const [editBlogVisible, setEditBlogVisible] = useState(false);
  const [blogStatus, setBlogStatus] = useState("");

  const [blogs, setBlogs] = useState([]);
  const [modalBlog, setModalBlog] = useState(null);

  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleViewClick = (item) => {
    setEditBlogVisible(true);
    setModalBlog(item);
  };

  useEffect(() => {
    api
      .getAllBlogs()
      .then((res) => {
        console.log(res, "blog response");
        setBlogs(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert("Something went wrong!! Please refresh the page.");
        console.log(err);
      });
  }, [blogStatus]);

  console.log(modalBlog, "checkdelete");

  if (loading)
    return (
      <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
      }}>
        <CircularProgress />
      </Box>
    );
  return (
    <Box sx={{ padding: "2.5rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography variant="titleText">Blogs</Typography>
        <ContainedButton
          label={"Upload Blog"}
          width={"fit-content"}
          px={"3rem"}
          backgroundColor={"#215A86"}
          borderRadius={"5rem"}
          onClick={() => setAddBlogVisible(true)}
        />
      </Box>
      <Grid container spacing={"2.5rem"}>
        {blogs.map((item) => {
          return (
            <Grid item xs={3}>
              <BlogCard onClick={() => handleViewClick(item)} item={item} />
            </Grid>
          );
        })}
      </Grid>

      {/* Add Blogs Modal */}
      <AddBlog
        open={addBlogVisible}
        handleClose={() => setAddBlogVisible(false)}
        onSuccess={() => {
          setBlogPreviewVisible(true);
        }}
        setPreviewData={setPreviewData}
        setBlogStatus={setBlogStatus}
      />

      {/* Blogs Preview Modal */}
      <PreviewBlogs
        open={blogPreviewVisible}
        handleClose={() => {
          setBlogPreviewVisible(false);
        }}
        previewData={previewData}
        onSuccess={(values) => {
          console.log(values, "testing");
          setAddBlogVisible(false);
          setBlogPreviewVisible(false);
          setsuccessVisible(true);
        }}
        setBlogStatus={setBlogStatus}
      />
      <SuccessModal
        open={successVisible}
        handleClose={() => setsuccessVisible(false)}
        label={"Blog Published Successfully"}
      />
      <EditBlogs
        setBlogStatus={setBlogStatus}
        open={editBlogVisible}
        handleClose={() => setEditBlogVisible(false)}
        blogData={modalBlog}
        // setEditdata={setEditdata}
        // setBlogEditorVisible={setBlogEditorVisible}
      />
    </Box>
  );
};

export default Blogs;
