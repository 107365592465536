import { createSlice } from "@reduxjs/toolkit";
import { setAuthorizationToken } from "../api/instances";

const initialState = {
  isAuthenticated: false,
  role: "",
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      setAuthorizationToken(action.payload.access_token);
      state.role = action.payload.data.role;
      state.user = action.payload.data.user;
      localStorage.setItem("role", action.payload.data.role);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout: (state) => {
      localStorage.removeItem("role");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      state.role = '';
      state.user = {};
      state.isAuthenticated = false;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login,setRole,setUser ,logout} = authSlice.actions;

export default authSlice.reducer;