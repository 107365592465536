import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ModalContainer from "../../../components/ModalContainer";
import TextInput from "../../../components/customInputs/TextInput";
import ContainedButton from "../../../components/buttons/ContainedButton";

import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../api/api";
import { createPromiseToast } from "../../../utils/createPromiseToast";

const AddCategory = ({ open, handleClose, onSuccess, setCategoryStatus }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async(values) => {
      setLoading(true);
      await api.createCategories(values).then(res=>{
        if(res.status ===200){
          setLoading(false);
          onSuccess();
          setCategoryStatus(`creating`);
          return {
            success:true,
            message:'Category added successfully'
          }
        }
      }).catch(err=>{
        setLoading(false);
        return {
          success:false,
          message:"Something went wrong"
        }
      }).then((td)=>{
        createPromiseToast(td);
      })
    },
  });

  return (
    <ModalContainer open={open}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "1rem",
          width: "100%",
          maxWidth: "45rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "2rem",
            pb: "1rem",
            borderBottom: "1px solid #D2D2D2",
          }}
        >
          <Typography variant="modalTitle">Add Category</Typography>
          <IconButton onClick={handleClose}>
            <ClearRoundedIcon color="primary" sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            p: "3rem",
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            mb: "2rem",
          }}
        >
          <TextInput
            label={"Name"}
            placeholder={"Enter Name"}
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.name}
            touched={formik.touched.name}
            name={"name"}
          />

          <ContainedButton
            label={loading ? <CircularProgress /> : "save"}
            onClick={formik.handleSubmit}
            type={"submit"}
            disabled={loading}
          />
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default AddCategory;
