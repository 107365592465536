import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContainedButton from "../../../components/buttons/ContainedButton";
import CategoryCard from "../../../components/cards/CategoryCard";
import AddCategory from "./AddCategory";
import SuccessModal from "../../../components/SuccessModal";
import { api } from "../../../api/api";

const BlogCategory = () => {
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [successVisible, setsuccessVisible] = useState(false);
  const [categories,setCategories] = useState([]);
  const [categoryStatus,setCategoryStatus] = useState('');
  const [loading, setLoading] = useState(true);

  const handleViewClick = () => {
    setsuccessVisible(true);
  };

  useEffect(() => {
    api
      .getAllCategories()
      .then((res) => {
        console.log(res, "blog response");
        setCategories(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        alert("Something went wrong!! Please refresh the page.")
      });
  }, [categoryStatus]);

  console.log(categories);
  if (loading)
  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent:'center',
      alignItems:'center'
    }}>
      <CircularProgress />
    </Box>
  );

  return (
    <Box sx={{ padding: "2.5rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography variant="titleText">Blog Category</Typography>
        <ContainedButton
          label={"Add Category"}
          width={"fit-content"}
          px={"3rem"}
          backgroundColor={"#215A86"}
          borderRadius={"5rem"}
          onClick={() => setAddCategoryOpen(true)}
        />
      </Box>
      <Grid container spacing={"2.5rem"}>
        {categories.map((item) => {
          return (
            <Grid item xs={3} md={4}>
              <CategoryCard onClick={handleViewClick} item={item} setCategoryStatus={setCategoryStatus}/>
            </Grid>
          );
        })}
      </Grid>

      <AddCategory
        open={addCategoryOpen}
        handleClose={() => {
          setAddCategoryOpen(false);
        }}
        onSuccess={()=>{
            setAddCategoryOpen(false)
            setsuccessVisible(true);
        }}
        setCategoryStatus={setCategoryStatus}
      />
      <SuccessModal
        open={successVisible}
        handleClose={() => setsuccessVisible(false)}
        label={"Category Added Successfully"}
      />
    </Box>
  );
};

export default BlogCategory;
