import { Button } from "@mui/material";
import React from "react";

const ContainedButton = ({ label, onClick, type, disabled, ...props }) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        fontWeight: "500",
        fontFamily: "Poppins",
        width: "100%",
        height: "100%",
        borderRight: "1rem",
        py: ".7rem",
        fontSize: "1.6rem",
        ...props,
        ":hover": {
          backgroundColor: props?.backgroundColor,
        },
      }}
      onClick={onClick}
      type={type || "text"}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ContainedButton;
