import React from "react";
import SideBar from "../../components/SideBar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../../components/Header";

const AuthOutlet = () => {
  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <SideBar />
      <Box sx={{ backgroundColor: "#F8F8F8", flex: 1 }}>
        <Header />
        <Box sx={{ height: "calc(100vh - 9.5rem)", overflowY: "scroll" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthOutlet;
