import { Box, Typography } from "@mui/material";
import React from "react";
import sfsLogo from "../assets/images/sfsLogo.png";

import { NavLink } from "react-router-dom";
import { navRoutes } from "../routes/routes";

const SideBar = () => {
  return (
    <Box sx={{ width: "26rem", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          height: "9.5rem",
          padding: "0rem 1.5rem",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "2px solid #E6EFF5",
        }}
      >
        <img
          src={sfsLogo}
          alt="sfs logo"
          style={{ width: "6rem", height: "6rem" }}
        />
        <Typography sx={{ fontSize: "1.3rem" }}>
          Surgeons For Surgeons Admin Panel
        </Typography>
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        {navRoutes.map((_route) => {
          return (
            <NavLink to={_route.path} style={{ textDecoration: "none" }}>
              {({ isActive, isPending, isTransitioning }) => (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    height: "5.5rem",
                    gap: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: ".6rem",
                      backgroundColor: isActive ? "#003162" : "transparent",
                      borderTopRightRadius: "1rem",
                      borderBottomRightRadius: "1rem",
                      marginRight: "2rem",
                    }}
                  />
                  {isActive ? (
                    <_route.activeIcon size={"3rem"} color="#003162" />
                  ) : (
                    <_route.inActiveIcon
                      size="3rem"
                      color="#B1B1B1
                    "
                    />
                  )}
                  <Typography
                    sx={{
                      textDecoration: "none",
                      color: isActive ? "#003162" : "#B1B1B1",
                      ":hover": {
                        color: "",
                      },
                    }}
                  >
                    {_route.routeName}
                  </Typography>
                </Box>
              )}
            </NavLink>
          );
        })}
      </Box>
    </Box>
  );
};

export default SideBar;
