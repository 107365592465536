import React, { useState } from "react";
import ModalContainer from "../../../components/ModalContainer";
import { Box, CircularProgress, Typography } from "@mui/material";
import ContainedButton from "../../../components/buttons/ContainedButton";
import bgImage from "../../../assets/images/loginBackground.png";
import { createPromiseToast } from "../../../utils/createPromiseToast";
import { api } from "../../../api/api";

const PreviewVideos = ({
  open,
  handleClose,
  onSuccess,
  previewData,
  setBlogStatus,
}) => {
  const [loading, setLoading] = useState(false);

  const publishBlog = async() =>{
    setLoading(true);
    const data = {...previewData};
    await api.createVideoBlog(data).then((res)=>{
      
      console.log(res, "api response");
      if(res.status === 200) {
        onSuccess();
        setBlogStatus('created');
        setLoading(false);
        return {
          success: true,
          message: "Blog Published successfully",
        };
      }
    }).catch(err=>{
      setLoading(false);
      console.log(err,"api response");
      return {
        success: false,
        message: err?.response?.data?.message,
      };
    }).then((td)=>{
      createPromiseToast(td);
    });
  }

  console.log(previewData);
  return (
    <ModalContainer open={open}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "1rem",
          width: "100%",
          maxWidth: "60vw",
          // marginTop: "15%",
          // marginBottom: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "2rem",
            pb: "1rem",
            borderBottom: "1px solid #D2D2D2",
          }}
        >
          <Typography variant="modalTitle">Video Preview</Typography>
        </Box>
        <Box
          sx={{
            py: "2.5rem",
            px: "5vw",
            display: "flex",
            gap: "1.5rem",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1.8",
              overflow: "hidden",
              borderRadius: ".5rem",
            }}
          >
            {/* <img src={bgImage} style={{ width: "100%", height: "100%" }} alt='video' /> */}
            <video width="400" controls poster={previewData?.thumbnail}>
              <source src={previewData?.videoUrl} type="video/mp4" />
            </video>
          </Box>
          <Box sx={{ pb: 2 }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "3.5rem",
                fontWeight: "700",
                color: "#2D3748",
              }}
            >
              {previewData?.title}
            </Typography>
            <Box sx={{ display: "flex", gap: "5rem" }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  color: "#2D3748",
                }}
              >
                Written by {previewData?.authorName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                  color: "#2D3748",
                }}
              >
                {previewData?.dateOfUpload}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            width: "50rem",
            py: "5rem",
            alignSelf: "center",
            marginX: "auto",
          }}
        >
          <ContainedButton
            label={"Go Back"}
            backgroundColor={" #00316259"}
            color={"#003162"}
            onClick={handleClose}
          />
          <ContainedButton label={loading?<CircularProgress/>:"Upload"} disabled={loading} onClick={publishBlog}/>
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default PreviewVideos;
