import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContainedButton from "../../../components/buttons/ContainedButton";
import BlogCard from "../../../components/cards/BlogCard";
import AddVideos from "./AddVideos";
import PreviewVideos from "./PreviewVideos";
import SuccessModal from "../../../components/SuccessModal";
import EditVideos from "./EditVideos";
import VideoCard from "../../../components/cards/VideoCard";
import { api } from "../../../api/api";

const Videos = () => {
  const [addVideosVisible, setAddVideosVisible] = useState(false);
  const [previewVisible, setpreviewVisible] = useState(false);
  const [successVisible, setsuccessVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blogStatus, setBlogStatus] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [modalBlog, setModalBlog] = useState(null);
  const [editVideoVisible, setEditVideoVisible] = useState(false);

  const handleViewClick = (item) => {
    setEditVideoVisible(true);
    setModalBlog(item);
  };

  useEffect(() => {
    api
      .getAllVideos()
      .then((res) => {
        console.log(res, "blog response");
        setBlogs(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert("Something went wrong!! Please refresh the page.");
        console.log(err);
      });
  }, [blogStatus]);

  if (loading)
  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent:'center',
      alignItems:'center'
    }}>
      <CircularProgress />
    </Box>
  );

  return (
    <Box sx={{ padding: "2.5rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography variant="titleText">Videos</Typography>
        <ContainedButton
          label={"Upload Videos"}
          width={"fit-content"}
          px={"3rem"}
          backgroundColor={"#215A86"}
          borderRadius={"5rem"}
          onClick={() => setAddVideosVisible(true)}
        />
      </Box>
      <Grid container spacing={"2.5rem"}>
        {blogs.map((item) => {
          return (
            <Grid item xs={3}>
              <VideoCard onClick={() => handleViewClick(item)} item={item} />
            </Grid>
          );
        })}
      </Grid>
      <AddVideos
        open={addVideosVisible}
        handleClose={() => {
          setAddVideosVisible(false);
        }}
        onSuccess={() => {
          setpreviewVisible(true);
        }}
        setPreviewData={setPreviewData}
        setBlogStatus={setBlogStatus}
      />
      <PreviewVideos
        open={previewVisible}
        handleClose={() => {
          setpreviewVisible(false);
        }}
        setBlogStatus={setBlogStatus}
        previewData={previewData}
        onSuccess={() => {
          setAddVideosVisible(false);
          setpreviewVisible(false);
          setsuccessVisible(true);
        }}
      />
      <SuccessModal
        open={successVisible}
        handleClose={() => setsuccessVisible(false)}
        label={"Video Uploaded Successfully"}
      />
      <EditVideos
        setBlogStatus={setBlogStatus}
        open={editVideoVisible}
        handleClose={() => setEditVideoVisible(false)}
        blogData={modalBlog}
      />
    </Box>
  );
};

export default Videos;
