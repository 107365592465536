import { Box } from "@mui/material";
import React from "react";
import LogoutDropdown from "./LogoutDropdown";
import { IoSearchOutline } from "react-icons/io5";
const Header = () => {
  return (
    <Box
      sx={{
        height: "9.5rem",
        borderBottom: "2px solid #E6EFF5",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        px: "3rem",
        gap:'2rem'
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#EFF5FF",
          padding: "1.3rem 3rem",
          borderRadius: "3rem",
          gap: "1rem",
        }}
      >
        <IoSearchOutline color={"#718EBF"} size={"2.5rem"} />
        <input
          placeholder="Search for something"
          type="search"
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
            color: "#003162",
            fontFamily:'Poppins',
            fontWeight:'500'
          }}
        />
      </Box>
      <LogoutDropdown />
    </Box>
  );
};

export default Header;
