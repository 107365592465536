import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { FiUser } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { createPromiseToast } from "../utils/createPromiseToast";

export default function LogoutDropdown({ label, onClick }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    createPromiseToast({
      success: true,
      message: "Logged out successfully",
    });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ borderRadius: "5rem" }}
      >
        <Button
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          startIcon={<FiUser color="#FFFFFF" size={"2.2rem"} />}
          sx={{
            color: "white",
            textTransform: "none",
            background: "#215A86",
            fontFamily: "Poppins",
            fontSize: "1.5rem",
            borderRadius: "5rem",
            py: "1rem",
          }}
          endIcon={
            <ArrowDropDownIcon
              sx={{
                fontSize: { md: "3rem !important", xs: "1rem" },
                transform: open ? "rotate(180deg)" : "",
              }}
            />
          }
        >
          Ajith
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          padding: 0,
          //   width: { md: "14rem", xs: "12rem" },
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              marginTop: ".5rem",
            }}
          >
            <Paper
              sx={{
                borderRadius: "5rem",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="logout-btn"
                  autoFocusItem
                  sx={{
                    padding: 0,
                    fontSize: "1rem",
                    width: "100%",
                    borderRadius: "5rem",
                    overflow: "hidden",
                  }}
                  onClick={handleLogout}
                >
                  <MenuItem
                    sx={{
                      ":hover": {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
