import React, { useEffect, useState } from "react";
import TextInput from "../../../components/customInputs/TextInput";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import ModalContainer from "../../../components/ModalContainer";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ContainedButton from "../../../components/buttons/ContainedButton";
import DropDown from "../../../components/customInputs/DropDown";
import VideoInput from "../../../components/customInputs/VideoInput";

import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../api/api";

const AddVideos = ({
  open,
  handleClose,
  onSuccess,
  setPreviewData,
  setBlogStatus,
}) => {
  const [videoFile, setVideoFile] = useState(null);
  const[videoFileErr,setVideoFileErr] = useState(false);
  const[loading,setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleSubmit = (values)=>{
    console.log(values);
    setVideoFileErr(false);
    if(videoFile){
      const formdata = new FormData();
          formdata.append('video',videoFile,videoFile.name);
          setLoading(true);
          api.uploadVideoBanner(formdata).then(res=>{
            console.log(res,'value');
            let  d = new Date();
            values = { ...values,videoUrl:res.data.data.videoUrl,dateOfUpload:d.toISOString(),thumbnail:res.data.data.thumbnailUrl};
            console.log(values, "value");
            setPreviewData(values);
            onSuccess(values);
            setLoading(false)
          }).catch(err=>setLoading(false));
    }

  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Email is required"),
    authorName: Yup.string().required("Author Name is required"),
    category: Yup.string().required("Category is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      category: "",
      dateOfUpload: "",
      authorName: "",
      videoUrl: "",
      thumbnail: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if(videoFile ===null) setVideoFileErr(true);
      else handleSubmit(values);
    },
  });

  useEffect(() => {
    api.getAllCategories().then((res) => {
      if (res.status === 200) {
        setCategories(res.data.data);
      }
    });
  }, []);


  return (
    <ModalContainer open={open}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "1rem",
          width: "100%",
          maxWidth: "60rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "2rem",
            pb: "1rem",
            borderBottom: "1px solid #D2D2D2",
          }}
        >
          <Typography variant="modalTitle">Upload Video</Typography>
          <IconButton onClick={handleClose}>
            <ClearRoundedIcon color="primary" sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            p: "2.5rem",
            display: "flex",
            gap: "1.5rem",
            flexDirection: "column",
          }}
        >
          <DropDown
            label="Category"
            menuItem={categories}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.category}
            name={"category"}
            placeholder="Selected category"
            error={formik.errors.category}
            touched={formik.touched.category}
          />
          <TextInput
            label={"Title"}
            placeholder={"Enter Title"}
            type="text"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.title}
            touched={formik.touched.title}
            name={"title"}
          />
          <Box sx={{ display: "flex", gap: "5rem" }}>
            {/* <TextInput
              label={"Date of Upload"}
              placeholder={"Enter Date of Upload"}
              type="date"
            /> */}
             <TextInput
              label={"Enter Author Name"}
              placeholder={"Author name"}
              value={formik.values.authorName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.authorName}
              touched={formik.touched.authorName}
              name={"authorName"}
              type="text"
            />
          </Box>
          <VideoInput
            label={"Upload Video"}
            placeholder={"No File Selected"}
            footerText={"Format .mp4, .avi : max 2GB"}
            value={videoFile}
            onChange={(e)=>{
              if (e.target.files) {
                setVideoFile(e.target.files[0]);
              }
            }}
            onBlur={formik.handleBlur}
            name= {'videoUrl'}
          />
          {videoFileErr && (
              <Typography
                sx={{
                  color: "red",
                  fontSize: { xs: "1.2rem" },
                  mt: { xs: "0.2rem" },
                }}
              >
                {"Video is required"}
              </Typography>
            )}
          <ContainedButton label={loading?<CircularProgress/>:"Preview"}
            onClick={formik.handleSubmit}
            type={"submit"}
            disabled={loading}/>
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default AddVideos;
